module.exports={
  "_from": "mapbox.js@^3.3.1",
  "_id": "mapbox.js@3.3.1",
  "_inBundle": false,
  "_integrity": "sha512-Vbco0r4DlQ4TOPZoj7Vl+rgZGie7ZWe3JL43fLzCnH3tC2AevqUFl5+mY8W+rwtd1oVsgfnNbIt4ZY4d9KkvBw==",
  "_location": "/mapbox.js",
  "_phantomChildren": {},
  "_requested": {
    "type": "range",
    "registry": true,
    "raw": "mapbox.js@^3.3.1",
    "name": "mapbox.js",
    "escapedName": "mapbox.js",
    "rawSpec": "^3.3.1",
    "saveSpec": null,
    "fetchSpec": "^3.3.1"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/mapbox.js/-/mapbox.js-3.3.1.tgz",
  "_shasum": "342e7230e3ae5d3da3a2f10d6f2ed409ea32a20e",
  "_spec": "mapbox.js@^3.3.1",
  "_where": "/app",
  "author": {
    "name": "Mapbox"
  },
  "bugs": {
    "url": "https://github.com/mapbox/mapbox.js/issues"
  },
  "bundleDependencies": false,
  "dependencies": {
    "@mapbox/corslite": "0.0.7",
    "@mapbox/sanitize-caja": "^0.1.4",
    "leaflet": "1.4.0",
    "mustache": "3.0.1"
  },
  "deprecated": false,
  "description": "Mapbox plugin for Leaflet",
  "devDependencies": {
    "browserify": "^16.5.0",
    "clean-css-cli": "^4.3.0",
    "eslint": "^6.8.0",
    "expect.js": "0.3.1",
    "happen": "0.3.2",
    "marked": "^0.8.0",
    "minifyify": "^7.3.5",
    "minimist": "1.2.0",
    "mocha": "^7.0.1",
    "mocha-phantomjs-core": "2.1.2",
    "phantomjs-prebuilt": "2.1.16",
    "sinon": "7.2.4"
  },
  "engines": {
    "node": ">=10"
  },
  "files": [
    "dist",
    "src",
    "*.md"
  ],
  "homepage": "http://mapbox.com/",
  "license": "BSD-3-Clause",
  "main": "src/index.js",
  "name": "mapbox.js",
  "optionalDependencies": {},
  "repository": {
    "type": "git",
    "url": "git://github.com/mapbox/mapbox.js.git"
  },
  "scripts": {
    "build": "make",
    "prepublishOnly": "npm run build",
    "test": "eslint src && phantomjs node_modules/mocha-phantomjs-core/mocha-phantomjs-core.js test/index.html && mocha test/docs.js"
  },
  "version": "3.3.1"
}
