import L from './leaflet-bubble';
import { Fsm } from 'machina';
import moment from 'moment';
import Lightpick from 'lightpick';

var $ = require('jquery');
const csrftoken = $("[name=csrfmiddlewaretoken]").val();

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
}
$.ajaxSetup({
    beforeSend: function(xhr, settings) {
        if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
            xhr.setRequestHeader("X-CSRFToken", csrftoken);
        }
    }
});

function onPageReady() {
  $(".dashboard-toggle").on("click touch", function () {
    $("#dashboard").toggleClass("hide");
  })

  $('#keywords').select2({
    placeholder: "Filter by keyword...",
  });

  $("#hamburger").on("click touch", function () {
    console.log("click touch hamburger");
    $("#sidebar-c").toggleClass("expand");
  });

  this.picker = new Lightpick({
      field: document.getElementById('date-picker1'),
      secondField: document.getElementById('date-picker2'),
      singleDate: false,
      // minDate: moment().startOf('month').subtract(2, 'month'),
      format: "MMMM DD, YYYY",
      onSelect: function(start, end){
        if (start && end) {
          console.log("We have a winner", start, end);
          siteMachine.setFilters({
            publishedAt_after: start.format("YYYY-MM-DD"),
            publishedAt_before: end.format("YYYY-MM-DD")
          });
        }
      }
  });

  $('#keywords').on('change', function (e) {
    var value = $('#keywords').val();
    console.log('change event', value);

    if (value) {
      var joined = value.join(',');
      siteMachine.setFilters({"keywords": joined});
    } else {
      siteMachine.removeFilters(["keywords"]);
    }
  });

  $('input:radio[name=eventType]').on("change", function (e) {
    var eventType = $(this).val();
    if (eventType == "everything") {
      siteMachine.removeFilters(["event_type"]);
    } else {
      siteMachine.setFilters({event_type: eventType});
    }
  });

  var picker = this.picker;
  $('#dateFilter').change(function() {
    var value = $(this).val();
    console.log(value);

    if (value == "custom") {
      $("#custom-date").removeClass("hide");
      picker.show();
    } else {
      $("#custom-date").addClass("hide");
      if (value == "everything") {
        siteMachine.removeFilters(["publishedAt_after", "publishedAt_before"]);
      } else {
        var filterDate = moment().subtract(value, 'days').format("YYYY-MM-DD");
        siteMachine.setFilters({"publishedAt_after": filterDate});
      }
    }
  });

  this.startRouting();
}

var siteMachine = new Fsm({
  initialize: function(options) {
    this.filters = {};
    this.map = initializeMap();
  },

  namespace: "map-machine",

  // `initialState` tells machina what state to start the FSM in.
  // The default value is "uninitialized". Not providing
  // this value will throw an exception in v1.0+
  initialState: "uninitialized",

  // The states object's top level properties are the
  // states in which the FSM can exist. Each state object
  // contains input handlers for the different inputs
  // handled while in that state.
  states: {
      uninitialized: {
        _onEnter: function () {
          $(document).ready(onPageReady.bind(this));
        },
      },
      show_usa: {
        filters_changed: function () {
          this.getBubbles();
        }
      },
      show_region: {
        _onEnter: function () {
          document.getElementById("sidebar-c").classList.add("expand");
        },

        filters_changed: function () {
          this.getBubbles();
          this.getRegionArticles();
        },

        close: function () {
          var url = new URL(window.location.href);
          url.searchParams.delete("county");
          history.pushState({href: url.href}, url.href, url.href);
          this.transition("show_usa");
        },

        _onExit: function () {
          document.getElementById("sidebar-c").classList.remove("expand");
        }
      }
  },

  startRouting: function () {
    var url = new URL(window.location.href);
    var county = url.searchParams.get("county");

    var checkCustom = false;
    ["keywords", "publishedAt_after", "publishedAt_before", "event_type"].forEach(function (param) {
      var value = url.searchParams.get(param);
      if (value) {
        this.filters[param] = value;

        if (param == "publishedAt_after") {
          var days = moment().diff(moment(value), 'days');
          if ([1, 7, 30].includes(days)) {
            $("#dateFilter").val(days);
          } else {
            // custom
            checkCustom = true;
          }
        }
        if (param == "keywords") {
          var words = value.split(",");
          $('#keywords').val(words);
          // Use change.select2 so that the on("change") callback won't fire
          $('#keywords').trigger('change.select2');
        }
        if (param == "event_type") {
          var radios = $('input:radio[name=eventType]');
          if (value != "everything") {
            radios.filter(`[value="${value}"]`).prop('checked', true);
          }
        }
      }
    }.bind(this));

    if (!url.searchParams.get("publishedAt_after") &&
        !url.searchParams.get("publishedAt_before")) {
      console.log("Use default 7 days ago");
      this.filters["publishedAt_after"] = moment().subtract(7, 'day').format("YYYY-MM-DD");
      $("#dateFilter").val("7");
    }

    if (checkCustom &&
            ("publishedAt_after" in this.filters) &&
            ("publishedAt_before" in this.filters)) {
      $("#dateFilter").val("custom");
      $("#custom-date").removeClass("hide");
      this.picker.setDateRange(
        moment(this.filters["publishedAt_after"]),
        moment(this.filters["publishedAt_before"])
      );
    }

    if (!url.searchParams.get("event_type")) {
      var everythingRadio = $('input:radio[name=eventType][value="everything"]');
      if (everythingRadio.length) {
        everythingRadio.prop('checked', true)
      } else {
        $('input:radio[name=eventType][value="targeted"]').prop('checked', true);
        this.setFilters({"event_type": "targeted"});
      }
    }

    if (county) {
      this.transition('show_region');
      this.getRegionArticles(county);
    } else {
      this.transition('show_usa');
    }
    this.getBubbles();
  },

  setFilters: function (filters) {
    var url = new URL(window.location.href);
    Object.keys(filters).forEach(function (filter) {
      url.searchParams.set(filter, filters[filter]);
    });
    history.pushState({href: url.href}, url.href, url.href);

    // behold the future
    this.filters = {...this.filters, ...filters};
    this.handle("filters_changed");
  },

  removeFilters: function (filters) {
    var url = new URL(window.location.href);
    filters.forEach(function (filter) {
      url.searchParams.delete(filter);
      delete this.filters[filter];
    }.bind(this));
    history.pushState({href: url.href}, url.href, url.href);

    this.handle("filters_changed");
  },

  showBubbles: function (data) {
    if (this.bubbles) {
      this.map.removeLayer(this.bubbles);
    }
    this.bubbles = L.bubbleLayer(data, {
        property: "r",
        legend: false,
        tooltipProps: {
          r: "Event Count",
          name: "Location"
        },
        onEachFeature: function (feature, layer) {
          layer.on({
              click: this.bubbleClick
          });
        }.bind(this)
    }).addTo(this.map);
  },

  showRegionArticles: function (data) {
    console.log("show_region:details", data);

    var placeName = "";
    if ("place" in data) {
      placeName = data.place;
    }
    $("#place-name").text(placeName);
    $("#place").addClass('hasdata');

    $("#article-list").removeClass("loading");


    this.populateArticleList(data.articles);

    var url = new URL(window.location.href);
    var articlePK = url.searchParams.get("article");
    if (articlePK) {
      var selector = $("[data-article-pk=" + articlePK + "]").parents("article");
      if (selector.length) {
        selector.addClass("highlight");
        selector.get(0).scrollIntoView();
      }
    }
  },

  bubbleClick: function (e) {
    var target = e.target;
    var url = new URL(window.location.href);

    var county = target.feature.properties.name;
    var oldCounty = url.searchParams.get("county");

    if (county == oldCounty) {
      return;
    }

    url.searchParams.set("county", county);
    history.pushState({href: url.href}, url.href, url.href);

    siteMachine.transition("show_region");
    document.getElementById("sidebar-c").classList.add("expand");
    siteMachine.getRegionArticles(county);
  },

  buildFilterString: function () {
    return Object.keys(this.filters).map(function (key) {
      return key + "=" + this.filters[key];
    }.bind(this)).join("&");
  },

  getBubbles: function () {
    $.get("/api/bubbles/?" + this.buildFilterString())
      .done(this.showBubbles.bind(this))
      .fail(function (error) {
        console.error(error);
      });
  },

  getRegionArticles: function (county) {
    if (!county) {
      var url = new URL(window.location.href);
      county = url.searchParams.get("county");
    }

    $("#place-name").text(county);
    $("#article-list").addClass("loading");

    $.get("/api/articles/?county=" + county + "&" + this.buildFilterString())
      .done(this.showRegionArticles.bind(this))
      .fail(function (error) {
        console.error(error);
      });
  },


  populateArticleList: function (data) {
    $("#article-list ol").html(data);
    var url = new URL(window.location.href);
    var county = url.searchParams.get("county");
    // $("#article-list ol").html(data.map(createArticle));
    $(".tag").on("click touch", function (e) {
      var slug = $(this).text().replace(/ /g, '-');
      var oldWords = $('#keywords').val();
      if (!oldWords) {
        $("#keywords").val([slug]);
        $("#keywords").trigger("change");
      } else if (!oldWords.includes(slug)) {
        $("#keywords").val([slug, ...$('#keywords').val()]);
        $("#keywords").trigger("change");
      }
    });

    if (this.permalinkClipboard) {
      this.permalinkClipboard.destroy();
    }
    this.permalinkClipboard = new ClipboardJS('.permalink', {
        text: function(trigger) {
            var href = (
              window.location.protocol +
              "//" +
              window.location.host +
              window.location.pathname +
              "?county=" +
              county.replace(/ /g, "%20") +
              "&" +
              this.buildFilterString() +
              "&article=" +
              trigger.getAttribute('data-article-pk')
            );
            console.log(href);
            return href;
        }.bind(this)
    });

    $(".vote").on("change", function (e) {
      let voteType;
      var data = {
        article: "",
        yes: $(this).val() == "yes",
      };

      [voteType, data.article] = $(this).attr("name").split("-");

      console.log("This user changes vote for", voteType, "to", data.yes, "for article", data.article);

      var feedbackEl = $(this).parent().siblings(".feedback")
      feedbackEl.html(`<i class="fas fa-circle-notch fa-spin"></i>`)

      var updateId = feedbackEl.attr("data-vote");

      var success = function (successData) {
        feedbackEl.html("<small>submitted by you now</small>");
        if (successData.voteId) {
          feedbackEl.attr("data-vote", successData.voteId);
        }
      };

      var fail = function (jqXHR, textStatus, errorThrown) {
        feedbackEl.html("Error: " + errorThrown);
      };

      if (updateId) {
        var url = `/api/${voteType}/` + updateId + "/";
        $.ajax({
          url: url,
          type: "PUT",
          data: data,
          success: success
        }).fail(fail);
      } else {
        $.post(`/api/${voteType}/`, data, success).fail(fail);
      }
    });
  }
});

// defunct this is now generated by server
// function createArticle(article) {
//   var tags = article.keywords.map(function (word) {
//     return `<span class="tag">${word}</span>`;
//   }).join("");

//   if (!tags) {
//     tags = "<small>none</small>"
//   }

//   return $(`<li class="article">
//   <article class="mb-3">
//     <div class="row">
//       <div class="col">
//         <img src="${article.urlToImage}">
//         <h5>${article.title}</h5>
//         <div class="tags mt-2"><i class="fas fa-tag night-label little-i"></i> ${tags}</div>
//         <span><i class="far fa-clock night-label little-i"></i> <time datetime="${article.publishedAt}">${article.publishedAt}</time></span>
//         <a href="${article.url}" class="mt-1" title="${article.url}" rel="noopener noreferrer" target="_blank"><i class="fas fa-link night-label little-i"></i> From ${article.domain} <i class="fas fa-external-link-alt"></i></a>
//       </div>
//     </div>
//     <div class="row mt-1">
//       <div class="col">
//         <p><i class="far fa-newspaper night-label little-i"></i> ${article.snippet}</p>
//       </div>
//     </div>
//     </article>
//     </li>`)
// }


window.onpopstate = function (event) {
  siteMachine.startRouting();
}

window.siteMachine = siteMachine;


// GLOBALS BECAUSE CODING IS HARD
// var leafletMap;
// var responseData;


// PAGE LOADED

// document.addEventListener("DOMContentLoaded", function() {
//     // rivets.bind(document.getElementById("main"), {app: app});

//     leafletMap = initializeMap();

//     ajax_get("response.geojson", function (data) {
//       initializeBubbles(data);
//     });
// });


// BUBBLES STUFF

// function initializeBubbles(data) {
//   var bubbles = L.bubbleLayer(data, {
//     property: "r"
//   }).addTo(leafletMap);



  // if (!("bubbles" in data)) {
  //   console.error("Cannot draw bubble chart, bubbles key missing from response");
  //   return;
  // }

  // console.log(data.bubbles);

//   data.bubbles.forEach(function (bubble) {
//     console.log(bubble);
//     var circle = L.circle([bubble[0], bubble[1]], {
//         color: 'red',
//         fillColor: '#f03',
//         fillOpacity: 0.5,
//         radius: bubble[2] * 3000
//     }).on('click', function () {
//       console.log("CLICKED ONE");


//     }).addTo(leafletMap);
//   });
// }


// MAP INIT STUFF

function initializeMap () {
    L.mapbox.accessToken = 'pk.eyJ1IjoibndzZWxmIiwiYSI6ImNpZWs0ZnppcDAwNTFzZW0wbHN4YmQzczcifQ.6Du542ZoRXxnghqRHj9VGQ';
    var map = L.map('map', {
        // minZoom: 5,
    }).setView([37.99616267972814, -89.296875], 4)  // TODO: try to get user's rough location?
      .addLayer(L.mapbox.styleLayer('mapbox://styles/mapbox/light-v10'));

    // L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=' + mapboxAccessToken, {
    //     id: 'mapbox.outdoors'
    // }).addTo(map);

    // var accessToken = 'pk.eyJ1IjoiZGFjdnQiLCJhIjoiY2o4dWVnczZmMHoxdDJ3cXFya3FzMGRqdSJ9.GxRPCSfZ6lbzjfNBosFwXg';

    // L.tileLayer(
    // 'https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=' + accessToken, {
    //     tileSize: 512,
    //     zoomOffset: -1,
    //     attribution: '© <a href="https://apps.mapbox.com/feedback/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
    // }).addTo(map);


    return map;
}

// VANILLA JS AJAX

// function
